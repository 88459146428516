/* Theme Name: Tripaco - Responsive Coming Soon Template
   Author: Krishthemes77
   Author e-mail: themesbykrish@gmail.com
   Version: 1.0
   Created: september 2016
   File Description:Main CSS file of the template
*/

/*================ TABLE OF CONTENTS ================
    1) Pre-loader
    2) Commone
    3) Navigation
    4) Home section
    5) Subscribe
    6) About-us
    7) Services Section
    8) Contact
    9) Footer
    10) Background-Parallax
    11) Typed Demo
    12) Video-Backgruond
================ TABLE OF CONTENTS ================*/

body {
  color: #505458;
  background-color: #f9f9f9;
  overflow-x: hidden !important;
  font-family: 'Roboto', sans-serif;
}
/*--------------------------------------------------------------
	1).Pre-loader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999999;
}
#status {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.spinner {
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 5px solid rgba(238, 110, 115, 0.2);
  border-right: 5px solid rgba(238, 110, 115, 0.2);
  border-bottom: 5px solid rgba(238, 110, 115, 0.2);
  border-left: 5px solid #d9ac4c;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.spinner,
.spinner:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*--------------------------------------------------------------
	2).Common
----------------------------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
}
p {
  font-family: 'Arimo', sans-serif;
}
.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}
.overlay-demo {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.5) !important;
}
#demo-canvas {
  position: absolute;
}
::selection {
  background: rgba(238, 110, 115, 0.3);
  color: #ffffff;
}
::-moz-selection {
  background: rgba(238, 110, 115, 0.3);
  color: #ffffff;
}
.section-title h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 30px;
  letter-spacing: 2px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}
.section-title p {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  margin: 30px 0px 40px;
  font-family: 'Arimo', sans-serif;
  line-height: 26px;
}
.background {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  z-index: 9;
  -webkit-filter: blur(3px);
  filter: blur(3px);
  left: 30px;
  right: 30px;
  bottom: 30px;
  top: 30px;
}
.flat-color {
  background: #2c3e50;
  background: -webkit-linear-gradient(to left, #2c3e50, #3498db);
  background: linear-gradient(to left, #2c3e50, #3498db);
}
.flat-color1 {
  background: #360033;
  background: -webkit-linear-gradient(to left, #360033, #0b8793);
  background: linear-gradient(to left, #360033, #0b8793);
}
.flat-color2 {
  background: #185a9d;
  background: -webkit-linear-gradient(to left, #185a9d, #43cea2);
  background: linear-gradient(to left, #185a9d, #43cea2);
}
.flat-color3 {
  background: #780206;
  background: -webkit-linear-gradient(to left, #780206, #061161);
  background: linear-gradient(to left, #780206, #061161);
}

.flat-color4 {
  background: #134e5e;
  background: -webkit-linear-gradient(to left, #134e5e, #71b280);
  background: linear-gradient(to left, #134e5e, #71b280);
}
.fullpage-wrapper {
  z-index: 99;
}
#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
  background: #d9ac4c !important;

}
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: #fff !important;
}
#fp-nav.right {
  right: 60px !important;
}
#fp-nav {
  margin-top: -75px !important;
}
#fp-nav ul li {
  margin: 0px 0px 30px 0px !important;
}
#fp-nav ul li:nth-child(n+7)
{
  display: none;
}

#fp-nav ul li .fp-tooltip {
  color: #d9ac4c !important;
}
#fp-nav ul li .fp-tooltip.right {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px !important;
  top: -5px;
  right: 40px !important;
}
.title h1 {
  color: #505458;
}
.title p {
  color: #787787;
}
.parallax {
  background-attachment: fixed !important;
}
.large-header {
  position: absolute;
  z-index: 999;
}
.contact-container {
  padding-top: 60px !important;
}
/*------------------------------
/****** Button******/
/* -------------------------------- */
.btn {
  outline: none !important;
}
.scribe {
  margin-top: 20px;
  text-align: center;
}
.scribe .btn-subscribe {
  color: #fff;
  background: #d9ac4c;
  font-weight: 400;
  padding: 9px 45px;
  border-radius: 0px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-size: 17px;
  border: 2px solid #fff;
  text-align: center;
  font-family: 'Arimo', sans-serif;
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.scribe .dark-btn {
  background: #d9ac4c;
  color: #fff;
  border: 2px solid #d9ac4c !important;
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.send-button {
  color: #fff;
  font-weight: 400;
  padding: 9px 45px;
  border-radius: 0px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-size: 16px;
  border: 2px solid #fff;
  background: #d9ac4c;
  text-align: center !important;
  font-family: 'Arimo', sans-serif;
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.send-button:hover,
.send-button:focus {
  background: #d9ac4c;
  color: #fff;
  outline: none !important;
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.gradient-button,
.gradient-button:hover,
.gradient-button:focus {
  color: #fff !important;
}
.button-dark {
  color: #fff;
  background: #d9ac4c;
  border: 2px solid #d9ac4c !important;
}
.button-dark:hover,
.button-dark:focus {
  outline: none !important;
  border: 2px solid #d9ac4c !important;
}

/*--------------------------------------------------------------
	3).Navigation
----------------------------------------------------------------*/
.header {
  top: 0;
  left: 0;
  right: 0;
  margin: 0px;
  width: 100%;
  border: none;
  height: auto;
  z-index: 1000;
  display: block;
  position: fixed;
  padding: 10px 30px;
  background: transparent;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
}
.navbar-custom {
  border: none;
  padding: 9px 0px;
  margin-bottom: 0px;
  border-radius: 0px;
}
.logo {
  height: auto;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 15px 15px;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
}
.logo span {
  width: 32px;
  height: 32px;
  color: #d9ac4c;
  font-size: 20px;
  font-weight: bold;
  padding: 0px 10px;
  line-height: 32px;
  margin-right: 5px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
}
.logo:hover,
.logo:focus {
  color: #fff;
}
.navbar-custom .navbar-nav li a {
  color: #fff;
  font-size: 18px;
  margin: 5px 0px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
}
.navbar-custom .nav > li > a:hover,
.navbar-custom .nav > li > a:focus {
  background: transparent;
  transition: all 0.3s linear;
  transform: translateY(-2px);
  text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
}
.navbar-custom .navbar-nav li.active a {
  color: #d9ac4c;
  background: transparent;
}
.navbar-header {
  margin-left: 15px !important;
}
.navbar-custom .dark-nav li a {
  color: #505458;
}
.dark-logo {
  color: #505458;
}
.dark-logo span {
  color: #fff !important;
  background: #505458 !important;
}
.dark-logo:hover,
.dark-logo:focus,
.dark-logo:active .dark-logo:hover,
.dark-logo:focus,
.dark-logo:active {
  color: #505458;
  outline: none;
}
/*---------------------------------------------*/
.navbar-toggle {
  background-color: transparent !important;
  margin-top: 14px;
  border: none;
  margin-right: 0px;
  padding: 9px 15px;
}
/*--------------------------------------------------------------
	4). Home Section
----------------------------------------------------------------*/
.home {
  background-repeat: no-repeat;
  padding-bottom: 0px !important;
  background: url('http://placehold.it/2000x1333') center;
  background-size: cover;
}
.image-background {
  padding-bottom: 0px;
  background-image: none;
}
.home-title p {
  color: #fff;
  margin: 0px;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
  text-transform: capitalize;
  margin-bottom: 30px;
  font-family: 'Arimo', sans-serif;
}
.home-title p.demo {
  color: #505458;
}
.home-title {
  width: 100%;
  max-width: 820px;
  margin: 20px auto 0 auto;
}
/*--------------------------------------------------------------
	4.1)Social
----------------------------------------------------------------*/
.social {
  top: 35px;
  left: 0;
  bottom: 0;
  height: 100%;
  position: fixed;
  display: table;
}
.social ul {
  margin: 0 auto;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.social li {
  margin: 0;
  display: block;
}
.social li a {
  display: block;
  background: transparent;
  position: relative;
  text-align: center;
  line-height: 63px;
  border-left: 1px solid transparent;
}
.social li a i {
  width: 40px;
  height: 40px;
  color: #d9ac4c;
  font-size: 20px;
  background: #fff;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  margin: 0px 10px 5px 20px;
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.social li a i:hover {
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.social li .facebook i {
  background: #3b5998;
  color: #fff;
}
.social li .twitter i {
  background: #55acee;
  color: #fff;
}
.social li .behance i {
  background: #1769ff;
  color: #fff;
}
.social li .dribbble i {
  background: #ea4c89;
  color: #fff;
}
.social li .linkedin i {
  background: #0077b5;
  color: #fff;
}
.social i:hover {
  color: #fff;
  background: #d9ac4c;
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
/*--------------------------------------------------------------
	4.2)Time circles
----------------------------------------------------------------*/
.time_circles {
  width: 100%;
  height: auto;
  position: relative;
}
.time_circles > div {
  position: absolute;
  text-align: center;
}
.time_circles > div > h4 {
  color: #fff;
  margin: 0px;
  padding: 0px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  font-size: 15px !important;
  text-transform: capitalize;
  line-height: 17px !important;
  font-family: 'Arimo', sans-serif;
}
.time_circles > div > span {
  color: #fff;
  width: 100%;
  display: block;
  font-size: 60px;
  font-weight: 600;
  margin-top: 0.4em;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}
.dark-circle .time_circles > div > h4 {
  color: #787787;
}
.dark-circle .time_circles > div > span {
  color: #505458;
}
/*--------------------------------------------------------------
	5).Subscribe section
----------------------------------------------------------------*/
.subscribe {
  background-repeat: no-repeat;
  background: url('http://placehold.it/2000x1333') center;
  background-size: cover;
}
.subscribe-section .email {
  margin: 0px;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Arimo', sans-serif;
}
.confirm-message form {
  max-width: 600px;
  margin: 0px auto;
  position: relative;
}
.confirm-message {
  margin-top: 40px;
  margin-bottom: 0px;
}
.input-group input {
  color: #fff;
  width: 100%;
  outline: none;
  font-size: 15px;
  letter-spacing: 1px;
  border-radius: 0px;
  padding: 18px 15px;
  border: 2px solid #fff;
  background-color: transparent;
  border-right: 0px;
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.input-group input:focus {
  box-shadow: none;
  border: 2px solid #fff;
  background: transparent;
}
.input-group input:hover {
  background: transparent;
  transition: all 0.3s ease 0s;
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
label.valid {
  color: #d9ac4c;
  font-weight: normal;
  margin: 10px 0;
  font-family: 'Arimo', sans-serif;
}
.error {
  margin: 8px 0px;
  color: #d9ac4c;
  z-index: 1100;
  font-weight: 400;
  font-family: 'Arimo', sans-serif;
}
.group-dark input {
  border: 2px solid #d9ac4c;
  color: #d9ac4c;
  border-right: none;
}
.group-dark input:focus {
  box-shadow: none;
  border: 2px solid #d9ac4c;
  background: transparent;
}
.dark-control::-webkit-input-placeholder {
  /* Chrome - */
  color: rgba(80, 84, 88, 0.78) !important;
}
.dark-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(80, 84, 88, 0.78) !important;
}
.dark-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(80, 84, 88, 0.78) !important;
}
.dark-control:-ms-input-placeholder {
  color: rgba(80, 84, 88, 0.78) !important;
}
::-webkit-input-placeholder {
  /* Chrome - */
  color: #fff !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}
:-ms-input-placeholder {
  color: #fff;
}
.subscribe-submit {
  top: -4px;
  color: #fff;
  font-size: 17px;
  padding: 9px 30px;
  border-radius: 0px;
  position: relative;
  background: #d9ac4c;
  letter-spacing: 1px;
  border: 2px solid #fff;
  outline: none !important;
  font-family: 'Arimo', sans-serif;
}
.subscribe-submit:hover {
  color: #fff;
  background-color: #d9ac4c;
}
.submit-darkbtn {
  background: #d9ac4c;
  border: 2px solid #d9ac4c;
}
/*--------------------------------------------------------------
	6).About Section
----------------------------------------------------------------*/
.about {
  background-repeat: no-repeat;
  background: url('http://placehold.it/2000x1333') center;
  background-size: cover;
}

.about-section h3 {
  color: #fff;
  font-size: 35px;
  margin-top: 20px;
  font-weight: 400;
  text-transform: capitalize;
}
/*---------- pie-charat	----------*/
.countdowm-main {
  margin-top: 10px;
}
.countdown {
  margin: auto 0px;
}
.countdown h1 {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  margin: 15px 0px;
  text-align: center;
}
.countdown p {
  color: #fff;
  font-size: 14px;
  text-align: center;
  font-family: 'Arimo', sans-serif;
}
.count-panal {
  text-align: center;
  margin: auto 0px;
  height: 150px !important;
}
.count-panal h1 {
  position: absolute;
  text-align: center;
  color: #fff;
}
.easy-pie-chart {
  border: none;
}
.percent-container span {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  position: absolute;
  font-size: 42px;
  text-align: center;
  font-weight: 300;
  display: inline-block;
  line-height: 140px;
  font-family: 'Roboto', sans-serif;
}
.percent-dark span {
  color: #787778;
}
canvas {
  margin-left: 0px !important;
}
.count-dark h1 {
  color: #505458;
}
.count-dark p {
  color: #787787;
}
/*--------------------------------------------------------------
	7).Services Section
----------------------------------------------------------------*/
.services {
  background-repeat: no-repeat;
  background: url('http://placehold.it/2000x1333') center;
  background-size: cover;
}
.services-icon {
  color: #fff;
  width: 130px;
  height: 130px;
  font-size: 50px;
  margin: 0px auto;
  line-height: 130px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-details h1 {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0px;
  text-align: center;
}
.service-details p {
  color: #fff;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  font-family: 'Arimo', sans-serif;
}
.service-dark h1 {
  color: #505458;
}
.service-dark p {
  color: #787787;
}
.icon_dark {
  color: #d9ac4c;
  border: 3px solid #d9ac4c;
}
/*--------------------------------------------------------------
	8).Contact Section
----------------------------------------------------------------*/
.contact-section {
  padding-bottom: 0px !important;
}
.contact {
  background-repeat: no-repeat;
  background: url('http://placehold.it/2000x1333') center;
  background-size: cover;
}
.form-group {
  margin-bottom: 7px;
}
.form-control {
  margin: 0px;
  color: #fff;
  font-size: 14px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  box-shadow: none;
  font-weight: 400;
  padding: 9px 0px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  box-sizing: border-box;
  background: transparent;
  -webkit-appearance: none;
  border-bottom: 2px solid #fff;
  font-family: 'Arimo', sans-serif;
}
.form-control:focus {
  box-shadow: none;
  border-left: none;
  border-right: none;
  outline: none !important;
  border-bottom: 2px solid #fff;
}
.dark-control {
  color: #d9ac4c;
  border-bottom: 2px solid #d9ac4c;
}
.dark-control:focus {
  outline: none !important;
  border-bottom: 2px solid #d9ac4c;
}
#success-form {
  font-size: 16px;
  width: 100%;
  color: #d9ac4c;
  display: none;
  clear: both;
  margin: 8px 0px;
  font-family: 'Arimo', sans-serif;
}
.parsley-errors-list {
  list-style: none;
  padding-left: 0px;
}
.parsley-errors-list li {
  font-family: 'Arimo', sans-serif;
  color: #d9ac4c;
}
/*--------------------------------------------------------------
	9).Footer
----------------------------------------------------------------*/
.footer {
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  z-index: 1200;
  margin: 0 auto;
  position: fixed;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 10px;
  background: transparent;
  font-family: 'Roboto', sans-serif;
}
.footer p {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 30px;
}
.dark-copyright p {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 30px;
  margin-bottom: 0px;
  color: #505458;
}
/*--------------------------------------------------------------
	10).Background-Parallax
----------------------------------------------------------------*/
.background-parallax {
  width: 100%;
  position: fixed !important;
  -moz-transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.background-parallax .bg-fixed {
  background-repeat: no-repeat;
  background: url('http://placehold.it/2000x1600') center;
  background-size: cover;
  height: 1600px;
  width: 2000px;
  z-index: 1300;
  position: fixed !important;
  -moz-transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
/*--------------------------------------------------------------
	11).Typed Demo
----------------------------------------------------------------*/
.dark-image {
  background: none;
}
.cd-headline.clip .cd-words-wrapper b {
  overflow: hidden;
  vertical-align: top;
  display: inline-block;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background: linear-gradient(
    to right,
    rgba(67, 206, 162, 0.77),
    rgba(24, 90, 157, 0.76)
  );
}
/*--------------------------------------------------------------
	12).Video-Backgruond
----------------------------------------------------------------*/
.fixed-bg #background-video {
  display: none;
}
#background-video {
  position: fixed !important;
}
#tubular-container {
  position: fixed !important;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
/*--------------------------------------------------------------
	13).Gradient 
----------------------------------------------------------------*/
#gradient {
  position: fixed !important;
  width: 100%;
  height: 100%;
}
/*--------------------------------------------------------------
	14).Responsive
----------------------------------------------------------------*/
@media (max-width: 767px) {
  .background {
    left: 15px;
    right: 15px;
    bottom: 15px;
    top: 15px;
  }
  .header {
    margin-top: 0px;
    padding: 0px 15px;
    background: rgba(14, 14, 14, 0.9);
  }
  .logo {
    padding: 15px 0px;
  }
  .navbar-custom .navbar-toggle .icon-bar {
    background-color: #ffffff;
  }
  .percent-container span {
    line-height: 120px;
  }
  .nav > li > a {
    padding: 10px 0px;
  }
  .navbar-nav {
    margin: 0px;
    text-align: center;
  }
  .section-title h1 {
    font-size: 26px;
    margin-bottom: 25px;
  }
  .home-title {
    padding-top: 200px;
  }
  .time_circles {
    height: 250px;
  }
  .home-title p {
    margin-bottom: 20px;
  }
  .background {
    left: 15px;
    right: 15px;
    bottom: 15px;
    top: 15px;
  }
  .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .navbar-header {
    margin-left: 0px;
  }
  .navbar > .container-fluid .navbar-brand {
    margin-left: 0px;
  }
  .subscribe-submit {
    padding: 10px 15px;
  }
  .about-section h3 {
    font-size: 26px;
  }
  .countdown canvas {
    height: 120px;
    width: 120px;
  }
  .services-icon {
    height: 120px;
    width: 120px;
    line-height: 120px;
  }
  .footer p {
    color: #141414;
    padding-bottom: 10px;
    margin-bottom: 0px;
  }
  .footer {
    background: #fff;
    position: relative;
    margin: 0px;
  }
  .social {
    position: relative;
    top: 0px;
    margin: 0 auto;
  }
  .social ul {
    padding-left: 0px;
  }
  .social li {
    display: inline-block;
  }
  .social li a {
    display: inline;
  }
  .social li a i {
    margin: 0px 10px 5px 20px;
  }
  .dark-logo {
    color: #fff;
  }
  .navbar-custom .dark-nav li a {
    color: #fff;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .home-title {
    padding-top: 70px;
  }
  .time_circles {
    height: 80px;
  }
  .time_circles > div > h4 {
    font-size: 12px !important;
  }
  .input-group input {
    font-size: 13px;
  }
  .subscribe-submit {
    top: -3px;
    font-size: 15px;
  }
  .about-chart canvas {
    height: 100px !important;
    width: 100px !important;
  }
  .countdown-time {
    max-width: 320px !important;
  }
  .countdown p {
    font-size: 13px;
  }
  .percent-container span {
    line-height: 100px;
  }
  .countdown h1 {
    font-size: 25px;
    margin-bottom: 15px;
  }
  .section-title p {
    font-size: 13px;
    margin: 15px 0px;
  }
  .services-icon {
    height: 100px;
    width: 100px;
    line-height: 100px;
  }
  .service-details p {
    font-size: 13px;
    margin-top: 10px;
  }
  .service-details h1 {
    font-size: 24px;
    margin: 13px 0px;
  }
  .social li a i {
    margin: 0px;
  }
  .social {
    position: relative;
    top: 0px;
    margin: 10px auto;
  }
  .social ul {
    padding-left: 0px;
  }
  .social li {
    display: inline-block;
  }
  .social li a {
    display: inline;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .background {
    left: 15px;
    right: 15px;
    bottom: 15px;
    top: 15px;
  }
  .header {
    padding: 0 15px;
    height: auto;
    position: fixed;
    -webkit-transform: scale3d(1, 1, 1);
    margin-top: 0px;
  }
  .navbar-collapse {
    padding-right: 0px;
  }
  .logo {
    padding-left: 0px;
  }
  .footer {
    background: #fff;
    position: relative;
    margin: 0px;
  }
  .social {
    position: relative;
    top: 0px;
    margin: 0px auto;
  }
  .social ul {
    padding-left: 0px;
  }
  .social li {
    display: inline-block;
  }
  .social li a {
    display: inline;
  }
  .social li a i {
    margin: 0px 10px 5px 20px;
  }
  .footer p {
    color: #141414;
    padding-bottom: 10px;
    margin-bottom: 0px;
  }
}
