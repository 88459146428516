.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.time_circles, .about-chart{
  display: none;
}

.language:hover{
  color:#d9ac4c;
}

/* Container alignment and padding */
.back-color {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  color: #ffffff;
  padding: 10px;
}

/* Countdown digits */
#days, #hours, #minutes, #seconds {
  font-size: 36px;
  color: #d9ac4c;
  margin: 0 5px;
}

/* Overall timer styling */
.countdown-timer {
  text-align: center;
  font-size: 2rem;
  padding: 10px;
  margin-top: 20px;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Text alignment */
.text-center {
  text-align: center;
}

/* Animation effects */
.animated.bounceIn {
  animation: bounceIn 2s ease-in-out;
}

/* Animation keyframes */
@keyframes bounceIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
    opacity: 1;
  }
  80% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@media screen and (max-width: 767px) {
  .h1-mobil{
    font-size: 28px !important;
    word-wrap: break-word;
  }
.partner-row{
  flex-direction: column !important;
}
.partner-title{
  display: block !important;
  font-size: 16px !important;
}
.partner-h1{
  display: none !important;
}

}